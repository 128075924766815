import * as React from 'react';
import { DefaultPalette, Stack } from '@fluentui/react';



// Styles definition

const itemStylesFooter1 = {
    /* marginRight: 20, */
    alignItems: 'center',
    //color: '#696969',
    color: '#1A1A1A',
    display: 'flex',
    height: 25,
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: 400

};

const itemStylesFooter2 = {
    /* marginRight: 20, */
    alignItems: 'center',
    //color: '#696969',
    color: '#1A1A1A',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 400

};

const mainStylesFooter = {
    /* marginRight: 20, */
    alignItems: 'center',
    color: '#1A1A1A',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: 500

};

const itemStyles = {
    /* marginRight: 20, */
    alignItems: 'center',
    color: DefaultPalette.white,
    display: 'flex',
    justifyContent: 'center',
    height: 70,

};

const itemLinkLabel = {
    //color: '#fff',
    textDecoration: 'none',
    fontSize: '14px'
};

const itemLink = {
    //color: '#0078D4',
    textDecoration: 'none',
};

// Tokens definition
const stackTokens = { childrenGap: 5 };

// Tokens definition
/* 
const containerStackTokens = { childrenGap: 5 };
const horizontalGapStackTokens = {
    childrenGap: 10,
    padding: 10,
};
const itemAlignmentsStackTokens = {
    childrenGap: 5,
    padding: 10,
};
 */
const stackStyles = {
    root: {

    },
};
const stackItemStyles = {
    root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 15,
        /* paddingBottom: 25, */
        //backgroundColor: 'rgba(241, 241, 241,1)'
        //backgroundColor: 'rgba(0, 120, 212,.05)'
        //backgroundColor: '#F2F6FF'
        
    },
};

const mainLabel = {
    color: '#0078D4'
}

const secontLabel = {
    color: 'rgba(0, 120, 212,.8)'
}

const topFooter = {
    paddingTop: 40,
    paddingBottom: 40,
    backgroundColor: 'rgba(0, 120, 212,.1)'
}




const MobileFooter = () => {
    return (
        
        <Stack>

            <Stack className="footer">
                {/* <Stack tokens={stackTokens}> 
                    <Stack horizontalAlign="center" style={topFooter}>
                        <div style={itemStylesFooter}><h2 style={mainLabel}>Check Leceipt</h2>
                        </div>
                        <span style={itemStylesFooter}><p style={secontLabel}>ระบบตรวจสอบเอกสาร e-Tax Invoice & e-Reciept</p></span>
                    </Stack>
                </Stack> */}

                <Stack vertical>
                    <Stack.Item grow={2} styles={stackItemStyles}>
                        <Stack horizontalAlign="center" styles={stackStyles}>
{/* 
                            <span style={itemStylesFooter}><h2 style={mainLabel}>พัฒนาโดย </h2></span>
                            <span style={itemStylesFooter}><h3 style={secontLabel}>บริษัท ฟรีเวชั่น จำกัด</h3></span>
                            <span style={itemStylesFooter}><h3 style={secontLabel}>682 หมู่ 1 ต.บรบือ อ.บรบือ จ.มหาสารคาม 44130</h3></span>
 */}
 
                            <span style={mainStylesFooter}>พัฒนาโดย</span>
                            <span style={itemStylesFooter1}>บริษัท ฟรีเวชั่น จำกัด</span>
                            <span style={itemStylesFooter2}>682 หมู่ 1 ต.บรบือ อ.บรบือ จ.มหาสารคาม 44130</span>
                            <span style={itemStylesFooter2}>📝<a href="https://www.esigns.cloud/th" target="_blank" style={{textDecoration: "none"}}>ระบบลงลายเซ็นอิเล็กทรอนิกส์</a></span>
                            <span style={itemStylesFooter2}>🧾<a href="https://www.leceipt.com" target="_blank" style={{textDecoration: "none"}}>ระบบออกใบกำกับภาษีอิเล็กทรอนิกส์</a></span>

                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow={2} styles={stackItemStyles} /* style={{ marginRight: "60px" }} */>
                        <Stack horizontalAlign="center" styles={stackStyles}>
                            <span style={mainStylesFooter}>ฝ่ายบริการลูกค้า</span>
                            <span style={itemStylesFooter2}><span className="contactTel">082-579-8555</span></span>

                            <span style={itemStylesFooter2}>อีเมล: support@esigns.cloud</span>
                            <Stack horizontal>
                                <a href="https://www.facebook.com/LeceiptOfficial" target="_blank" rel="nofollow"><img src="images/facebook_icon.png" alt="Add line leceipt" style={{ height: "47px", marginRight: "25px"}}/></a>
                                <a href="https://line.me/R/ti/p/%40455ixoox" target="_blank" rel="nofollow"><img src="images/line-add-friend.png" alt="Add line leceipt" style={{ width: "150px"}}/></a>
                            </Stack>
                        </Stack>
                    </Stack.Item>

                </Stack>



            </Stack>

            <br/>
            <Stack tokens={stackTokens} className="copyRight">
                <Stack horizontal horizontalAlign="center" styles={stackStyles}>
                    <span style={itemStyles}><a style={itemLinkLabel} href="https://www.frevation.com/" target="_blank">สงวนลิขสิทธิ์ &copy; บริษัท ฟรีเวชั่น จำกัด</a></span>
                    {/* 
                    <span style={itemStyles}><a style={itemLink} href="https://www.leceipt.com/terms-conditions"> | ข้อกำหนดการใช้งาน | นโยบายบายความเป็นส่วนตัว | นโยบายคืนเงิน | นโยบายยกเลิก | </a></span>
                    <span style={itemStyles}><a style={itemLink} href="https://www.leceipt.com/privacy-policy">Privacy Policy</a></span>
                     */}
                </Stack>
            </Stack>
        
       </Stack>
    )
}

export default MobileFooter