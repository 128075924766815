import React from 'react';
import Header from './Header';
import Upload from './Upload';
import Footer from './Footer';

import MobileHeader from './MobileHeader';
import MobileUpload from './MobileUpload';
import MobileFooter from './MobileFooter';
import { 
    Stack
    
} from '@fluentui/react';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

const stackStyles = {
    root: {
      
      height: '100vh',      
      margin: 0,
      padding: 0,
      minWidth: '1400px',
      minHeight: '100%',      
      
    },
  };

const mobileStackStyles = {
root: {
    width: '100vw',
    height: '100vh',      
    margin: 0,
    padding: 0,
    minWidth: '100%',
    minHeight: '100%',      
    
},
};

function Home() {

    return (
        <Stack>
            <BrowserView>
                <Stack vertical verticalAlign="space-between" styles={stackStyles} disableShrink={true} wrap={false}>
                    <Stack>
                        <Header />
                        <Upload />            
                    </Stack>
                    <Footer />
                </Stack>
            </BrowserView>
            <MobileView>
                <Stack vertical verticalAlign="start" styles={mobileStackStyles} disableShrink={true} wrap={false}>
                    <MobileHeader />
                    <MobileUpload />
                    <MobileFooter />
                </Stack>
            </MobileView>

        </Stack >

    );
}

export default Home;