
import React from 'react';
import './App.css';

import Home from './components/Home'
//import PagePdf from './components/PagePdf'
//import Test from './components/Test'

function App() {

  return (
    <div>
      <Home />

      {/* <li><a><Link to="/pagepdf">PDF</Link></a></li> */}

      {/* <Route exact path="/"><Home /></Route>
      <Route path="/pagepdf"><PagePdf /></Route>
 */}
    </div >

  );
}

export default App;
